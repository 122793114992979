<template>
    <div class="wrapper">
        <CheckStatusDescription />
        <p v-if="!isCheckOutFree" class="mt-40">
            {{ $t('cashModal.description') }}
        </p>
    </div>
</template>

<script>
    import CheckStatusDescription from '@/components/Order/ModalContent/CheckStatusDescription.vue';

    export default {
        name: 'CashModalContent',
        components: { CheckStatusDescription },
        props: {
            isCheckOutFree: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        text-align: center;
        max-width: 320px;

        .btn {
            display: inline;

            font-size: $font-16;
        }
    }
</style>
