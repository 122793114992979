<template>
    <p>
        {{ $t('cashModal.youCanCheckStatus') }}
        <CRButton @click="toOrderManagement" class="btn" pattern="thin">
            {{ $t('buttons.orderManagement') }}
        </CRButton>
        {{ $t('cashModal.ofUserPanel') }}
    </p>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { mapActions } from 'vuex';

    export default {
        name: 'CheckStatusDescription',
        components: { CRButton },
        methods: {
            ...mapActions('cart', ['clearCartCookies']),

            toOrderManagement() {
                this.$router.push({ name: 'allUserCheckOuts' });

                if (this.$route.name === 'checkOutPayment') {
                    this.clearCartCookies();
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn {
        display: inline;

        font-size: $font-16;
    }
</style>
